<script setup lang="ts">
import OzBaseButton, { OzBaseButtonHrefMode } from '@@/library/v4/components/OzBaseButton.vue'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    separator?: OzListRowSeparator
    darkMode?: boolean | 'auto'
    clickable?: boolean
    href?: string
    hrefMode?: OzBaseButtonHrefMode
    disabled?: boolean
    isButton?: boolean
  }>(),
  {
    separator: OzListRowSeparator.Short,
    darkMode: 'auto',
    clickable: true,
    href: undefined,
    hrefMode: OzBaseButtonHrefMode.Default,
    disabled: false,
    isButton: false,
  },
)

const emit = defineEmits(['click'])

const xSeparator = computed((): boolean => props.separator !== 'none')

const lineSeparatorClass = computed((): Record<string, boolean> => {
  const colorClasses = {
    'after:bg-divider-gray dark:after:bg-divider-gray-dark': props.darkMode === 'auto',
    'after:bg-divider-gray': props.darkMode === false,
    'after:bg-divider-gray-dark': props.darkMode === true,
  }
  return {
    "after:content-['']": true,
    'after:absolute': true,
    'after:h-px': true,
    'after:bottom-[-4px]': true,
    ...colorClasses,
  }
})

const onClick = (): void => {
  if (props.disabled) {
    return
  }
  emit('click')
}
</script>

<script lang="ts">
export enum OzListRowSeparator {
  None = 'none',
  Short = 'short',
  Long = 'long',
  FullWidth = 'full-width',
}

export { OzBaseButtonHrefMode as OzListRowHrefMode } from '@@/library/v4/components/OzBaseButton.vue'
export default {}
</script>

<template>
  <component
    :is="href || isButton ? OzBaseButton : 'div'"
    :href="href"
    :href-mode="hrefMode"
    :disabled="disabled"
    :class="[
      {
        relative: separator === OzListRowSeparator.Long || separator === OzListRowSeparator.FullWidth,
        flex: true,
        'items-center text-start': true,
        'cursor-pointer': clickable,
        'mb-[calc(0.5em+1px)]': xSeparator,
        'after:inset-x-0': separator === OzListRowSeparator.FullWidth,
        'after:inset-x-1': separator === OzListRowSeparator.Long,
        'rounded-lg': true,
        'bg-transparent p-0': href || isButton,
      },
      (separator === OzListRowSeparator.Long || separator === OzListRowSeparator.FullWidth) && lineSeparatorClass,
    ]"
    :tabindex="clickable ? 0 : -1"
    @click="onClick"
    @keydown.enter="onClick"
    @keydown.space="onClick"
  >
    <slot name="left"></slot>
    <div
      :class="[
        'flex',
        'grow',
        'min-w-0',
        'items-center',
        'justify-between',
        separator === OzListRowSeparator.Short && ['relative', 'after:inset-x-0', lineSeparatorClass],
      ]"
    >
      <slot></slot>
      <slot name="right"></slot>
    </div>
  </component>
</template>
